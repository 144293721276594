export const beikeDetail1 = [
	{
		id: 1,
		defalut: require('@/assets/img/贝壳系列素材/产品模特图/BKCY1.jpg'),
		active: require('@/assets/img/贝壳系列素材/产品模特图/BKCY2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳镜面唇釉',
		intro: '跳跃亮色 辣妹必备',
		zhName: '酵色「贝壳镜面唇釉」',
		enName: 'Spiral Shell Glazed Lipgloss',
		price: '79',
		of: '3.2g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/1.jpg') },
		],
		color: [
			{
				active: '#942825',
				name: '#01 杨枝甘露',
				span1: '纯欲橘调  可盐可甜',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/01.jpg')
			},
			{
				active: '#851726',
				name: '#02 葡萄冻冻',
				span1: '葡萄细闪 明媚气质',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/02.jpg')
			},
			{
				active: '#a5212b',
				name: '#04 草莓汽酒',
				span1: '软糯草莓 俏皮活力',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/04.jpg')
			},
			{
				active: '#8a2214',
				name: '#06 脏脏奶咖',
				span1: '脏脏茶棕 复古百搭',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/06.jpg')
			},
			{
				active: '#9b3a3d',
				name: '#07 鸳鸯奶茶',
				span1: '清爽豆沙 纯欲轻熟',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/07.jpg')
			},
			{
				active: '#8f3529',
				name: '#08 焦糖奶茶',
				span1: '焦糖橘棕 日杂氛围',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/08.jpg')
			},
			{
				active: '#bd4851',
				name: '#09 桃心水母',
				span1: '肉桂蜜粉 甜美丰盈',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/09.jpg')
			},
			{
				active: '#be5957',
				name: '#10 海盐奶咖',
				span1: '裸感奶茶色 显色温柔',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/10.jpg')
			},
			{
				active: '#b7364e',
				name: '#11 半熟葡萄',
				span1: '果味十足 轻熟妩媚',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/11.jpg')
			},
			{
				active: '#ad3e48',
				name: '#12 柿子茶冻',
				span1: '红茶果冻 半熟少女',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/12.jpg')
			},
			{
				active: '#84141c',
				name: '#31 逃离银河',
				span1: '浓郁血浆色 气场显白',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/13.jpg')
			},
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/贝壳系列素材/产品模特图/BKGG1.jpg'),
		active: require('@/assets/img/贝壳系列素材/产品模特图/BKGG2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳高光',
		intro: '轻盈细腻 高级光泽',
		zhName: '酵色「贝壳高光」',
		enName: 'Spiral Shell Highlighter',
		price: '69',
		of: '3.6 - 4.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d4.jpg') },
		],
		color: [
			{
				active: '#dddddd',
				name: '#01 月光碎石',
				span1: '银色大闪',
				span2: '聚焦闪耀白月光',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/01.jpg')
			},
			{
				active: '#cbb2cd',
				name: '#02 耀眼薄雾',
				span1: '粉紫大闪偏光',
				span2: '甜系妆容必备',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/02.jpg')
			},
			{
				active: '#deb599',
				name: '#03 日光之吻',
				span1: '自然香槟金细闪',
				span2: '湿漉漉的水光感',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/03.jpg')
			},
			{
				active: '#f3eed5',
				name: '#05 寻迹流星',
				span1: '蓝粉香槟烤粉',
				span2: '清透妆效',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/05.jpg')
			}
		]
	},
]

export const beikeDetail2 = [
	{
		id: 1,
		defalut: require('@/assets/img/贝壳系列素材/产品模特图/BKSH1.jpg'),
		active: require('@/assets/img/贝壳系列素材/产品模特图/BKSH2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳腮红',
		zhName: '酵色「贝壳腮红」',
		enName: 'Spiral Shell Blush',
		intro: '天然好气色 点缀清透红润',
		price: '79',
		of: '3.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/腮红/小图/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/腮红/小图/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/腮红/小图/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/腮红/小图/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/1.jpg') },
		],
		color: [
			{
				active: '#e3c3ba',
				name: '#N03 迷路晨雾',
				span1: '自然裸粉 少女气色',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/n03.jpg')
			},
			{
				active: '#c28f8b',
				name: '#N04 糖渍早樱',
				span1: '灰调肉粉 清透细闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/N04.jpg')
			},
			{
				active: '#af7178',
				name: '#N05 碎金玫瑰',
				span1: '烟熏玫瑰 朦胧偏光',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/n05.jpg')
			},
			{
				active: '#a16b5f',
				name: '#B03 肉桂赤岩',
				span1: '高级暖棕 细腻金闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/b03.jpg')
			}
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/贝壳系列素材/产品模特图/BKYP1.jpg'),
		active: require('@/assets/img/贝壳系列素材/产品模特图/BKYP2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳眼盘',
		intro: '三重质地闪片 点染灵动双眸',
		zhName: '酵色「贝壳综合盘」',
		enName: 'Spiral Shell Eyeshadow Palette',
		price: '149',
		of: '9g',

		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d4.jpg') },
		],
		color: [
			{
				active: '#a47258',
				name: '#07 金棕日出',
				span1: '暖调层次感配色 粉调温暖细闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/贝壳眼盘/07.jpg')
			},
			{
				active: '#c58b95',
				name: '#08 粉雾漂流',
				span1: '梦幻粉紫组合 三重质地闪片',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/贝壳眼盘/08.jpg')
			},
		]
	},
	{
		id: 3,
		defalut: require('@/assets/img/贝壳系列素材/产品模特图/BKFB1.jpg'),
		active: require('@/assets/img/贝壳系列素材/产品模特图/BKFB2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳粉饼',
		zhName: '酵色「贝壳粉饼」',
		enName: 'Spiral Shell Amplifier Powder',
		intro: 'Q弹帖肤不卡粉 清透柔焦伪素颜',
		price: '129',
		of: '10g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg') },
		],
		color: [
			{
				active: '#f1e8d9',
				name: '#01 自然色',
				span1: '为亚洲肤色定制',
				span2: '奶油淡黄色粉体 遮瑕修肤',
				color: require('@/assets/img/详情/贝壳素材/贝壳粉饼/001.jpg')
			}
		]
	}
]

